<template>
  <div>
    <el-form
        ref="form"
        :model="dataForm"
        label-width="120px"
        :rules="dataRule"
    >
      <b-card>
        <b-row>
          <b-col><h2>请求概况</h2></b-col>
        </b-row>
        <b-row style="margin-top: 10px;">
          <b-col md="4">
            <el-form-item
                label="请求编号"
                prop="serialNumber">
              <el-input v-model="dataForm.serialNumber" placeholder="请求编号"></el-input>
            </el-form-item>
          </b-col>
          <b-col md="4">
            <el-form-item
                label="接收日期"
                prop="receivedTime">
              <el-date-picker
                  v-model="dataForm.receivedTime"
                  placement="bottom-start"
                  format="yyyy-MM-dd HH:ss:mm"
                  value-format="yyyy-MM-dd HH:ss:mm"
                  type="datetime"
                  placeholder="选择日期"
                  :picker-options="pickerOptionsStart"
              />
            </el-form-item>
          </b-col>
          <b-col md="4">
            <el-form-item
                label="接收人员"
                prop="receivedUser">
              <el-select v-model="dataForm.receivedUser" clearable>
                <el-option
                    v-for="(item,index) in userList"
                    :key="index"
                    :label="item.actualName"
                    :value="item.id"
                />
              </el-select>
            </el-form-item>
          </b-col>
        </b-row>
        <el-form-item
            label="关联请求"
            prop="relatedIdList">
          <el-select v-model="dataForm.relatedIdList" multiple clearable>
            <el-option
                v-for="(item,index) in requestList"
                :key="index"
                :label="item.name"
                :value="item.id"
            />
          </el-select>
        </el-form-item>
      </b-card>
      <b-card>
        <b-row>
          <b-col>
            <h2>个人信息主体情况</h2>
          </b-col>
        </b-row>
        <b-row style="margin-top: 10px">
          <b-col md="4">
            <el-form-item
                label="主体姓名"
                prop="userName">
              <el-input v-model="dataForm.userName" placeholder="主体姓名"></el-input>
            </el-form-item>
          </b-col>
          <b-col md="4">
            <el-form-item
                label="联系电话"
                prop="userPhone">
              <el-input v-model="dataForm.userPhone" placeholder="联系电话"></el-input>
            </el-form-item>
          </b-col>
          <b-col md="4">
            <el-form-item
                label="联络邮箱"
                prop="userEmail">
              <el-input v-model="dataForm.userEmail" placeholder="联络邮箱"></el-input>
            </el-form-item>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <el-form-item
                label="其他识别信息"
                prop="userOtherInfo">
              <el-input type="textarea" :rows="4" v-model="dataForm.userOtherInfo" placeholder="其他个人信息主体识别信息"></el-input>
            </el-form-item>
          </b-col>
        </b-row>
      </b-card>
      <b-card>
        <b-row>
          <b-col><h2>个人信息主体请求内容</h2></b-col>
        </b-row>
        <b-row style="margin-top: 10px;">
          <b-col>
            <el-form-item
                label="涉及业务"
                prop="productIdList">
              <el-select v-model="dataForm.productIdList" multiple clearable>
                <el-option
                    v-for="(item,index) in productList"
                    :key="index"
                    :label="item.name"
                    :value="item.id"
                />
              </el-select>
            </el-form-item>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <el-form-item
                label="涉及数据"
                prop="dataInfo"
            >
              <el-input type="textarea" :rows="4" v-model="dataForm.dataInfo" placeholder="其他个人信息主体识别信息"></el-input>
            </el-form-item>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <el-form-item
                label="请求权利类型"
                prop="rightTypeList">
              <el-select v-model="dataForm.rightTypeList" multiple clearable @change="setRightMsg">
                <el-option
                    v-for="(item,index) in rightTypeList"
                    :key="index"
                    :label="item.name"
                    :value="item.id"
                />
              </el-select>
            </el-form-item>
          </b-col>
          <b-col md="6">
            <span >信息提示：<br></span>
            <div v-for="(item,index) in rightTypeMsgList" :key="index">
              <span style="white-space: pre-wrap;">{{index +1}}、{{item}}</span><br>
            </div>
            <br>
          </b-col>
        </b-row>
        <el-form-item
            label="请求具体内容"
            prop="requestInfo">
          <el-input type="textarea" :rows="8" v-model="dataForm.requestInfo" placeholder="说明"></el-input>
        </el-form-item>
        <el-form-item
            label="请求紧迫程度"
            prop="handleLevel">
          <el-select v-model="dataForm.handleLevel" clearable>
            <el-option
                v-for="(item,index) in levelList"
                :key="index"
                :label="item.name"
                :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item
            label="附件">
          <el-upload
              class="upload-demo"
              :action="serverPath+'/sys/upload/file'"
              :headers="headers"
              :on-success="uploadFileSuccess"
              :on-remove="removeFile"
              :file-list="fileList">
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
        </el-form-item>
      </b-card>
    </el-form>

    <div class="dialog-footer text-center">
      <el-button @click="back">
        取 消
      </el-button>
      <el-button
          type="primary"
          :loading="loading"
          @click="submitForm"
      >
        提 交
      </el-button>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
// eslint-disable-next-line import/no-cycle
import { queryProductSelect } from '@/api/enterprise/product'
import { queryUserOptions } from '@/api/system/system'
import moment from 'moment'

export default {
  data() {
    return {
      loading: false,
      userList: [],
      rightTypeList: [],
      productList: [],
      requestList: [],
      dataForm: {
        id: 0,
        FlowId: 0,
        serialNumber: '',
        receivedTime: '',
        receivedUser: undefined,
        userName: '',
        userPhone: '',
        userEmail: '',
        userOtherInfo: '',
        dataInfo: '',
        requestInfo: '',
        handleLevel: undefined,
        rightTypeList: [],
        productIdList: [],
        annexList: [],
        relatedIdList: [],
      },
      pickerOptionsStart: {
        disabledDate: time => time.getTime() < Date.now() - 8.64e7,
      },
      rightTypeMsgList: [],
      levelList: [
        { id: 1, name: '非常紧急（1天内解决并给出合理解释）' },
        { id: 2, name: '紧急（3天内解决并给出合理解释）' },
        { id: 3, name: '高优先（7天内解决并给出合理解释）' },
        { id: 4, name: '优先（15天内解决并给出合理解释）' },
        { id: 5, name: '一般（30天内解决并给出合理解释）' },
      ],
      count: 1,
      queryParams: {
        flowId: 0,
        pageNo: 1,
        pageSize: 10,
      },
      serverPath: window.g.baseUrl,
      fileList: [],
      headers: { 'x-token': '' },
    }
  },
  computed: {
    dataRule() {
      return {
        serialNumber: [
          { required: true, message: '编号不能为空。', trigger: 'blur' },
        ],
        receivedTime: [
          { required: true, message: '接收时间不能为空。', trigger: 'blur' },
        ],
        receivedUser: [
          { required: true, message: '接收人不能为空。', trigger: 'blur' },
        ],
        userName: [
          { required: true, message: '姓名不能为空。', trigger: 'blur' },
        ],
        userPhone: [
          { required: true, message: '电话不能为空。', trigger: 'blur' },
        ],
        requestInfo: [
          { required: true, message: '请求内容不能为空。', trigger: 'blur' },
        ],
        rightTypeList: [
          {
            type: 'array', required: true, message: '权力类型不能为空。', trigger: 'change',
          },
        ],
        productIdList: [
          {
            type: 'array', required: true, message: '请至少选择一个业务。', trigger: 'change',
          },
        ],
      }
    },
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.headers['x-token'] = localStorage.getItem('accessToken')
      this.queryParams = this.$route.query
      this.getRequestList()
      this.getRightType()
      this.getProduct()
      this.getUserSelect()
      this.dataForm.flowId = Number(this.$route.query.flowId)
      this.dataForm.receivedTime = moment().format('YYYY-MM-DD HH:mm:ss')
      const userData = JSON.parse(localStorage.getItem('userData'))
      this.dataForm.receivedUser = userData.id
      if (this.queryParams.id !== undefined && this.queryParams.id !== '') {
        this.getInfo(this.queryParams.id)
      } else {
        this.getSerialNumber()
      }
    },
    uploadFileSuccess(response, file) {
      const { savePath, fileName } = response.data
      this.fileList.push({
        name: fileName,
        url: `${this.serverPath}/temp${savePath}`,
        savePath,
        fileName,
        uid: file.uid,
      })
    },
    removeFile(file) {
      this.fileList = this.fileList.filter(item => item.uid !== file.uid)
    },
    getSerialNumber() {
      this.$http.get('/custom/request/serial/number').then(({ data: res }) => {
        this.dataForm.serialNumber = res.data
      }).catch(() => {})
    },
    getUserSelect() {
      queryUserOptions().then(res => {
        if (res.data.code === 0) {
          this.userList = res.data.data
        }
      })
    },
    getRightType() {
      this.$http.get('/custom/request/right/select').then(({ data: res }) => {
        if (res.code !== 0) {
          this.$message.error(res.msg)
        } else {
          this.rightTypeList = res.data
        }
      }).catch(() => {})
    },
    getProduct() {
      queryProductSelect().then(({ data: res }) => {
        if (res.code !== 0) {
          this.$message.error(res.msg)
        } else {
          this.productList = res.data
        }
      })
    },
    getRequestList() {
      this.$http.get('/custom/request/select').then(({ data: res }) => {
        if (res.code !== 0) {
          this.$message.error(res.msg)
        } else {
          this.requestList = res.data
        }
      }).catch(() => {})
    },
    // 获取信息
    getInfo(id) {
      this.$http.get(`/custom/request/info?id=${id}`).then(({ data: res }) => {
        if (res.code !== 0) {
          this.$message.error(res.msg)
        } else {
          this.dataForm = {
            ...this.dataForm,
            ...res.data,
          }
          this.fileList = this.dataForm.annexList
          if (this.dataForm.handleLevel === 0) {
            this.dataForm.handleLevel = undefined
          }
          this.setRightMsg()
        }
      }).catch(() => {})
    },
    setRightMsg() {
      this.rightTypeMsgList = this.rightTypeList.filter(item => this.dataForm.rightTypeList.includes(item.id)).map(item => item.explain)
    },
    back() {
      const pageParams = {
        pageNo: this.queryParams.pageNo,
        pageSize: this.queryParams.pageSize,
      }
      this.$router.push({
        path: '/custom/list',
        query: pageParams,
      })
    },
    submitForm: debounce(function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          this.dataForm.annexList = this.fileList
          this.$http.post(!this.dataForm.id ? '/custom/request/create' : '/custom/request/update', this.dataForm).then(({ data: res }) => {
            if (res.code !== 0) {
              this.$message.error(res.msg)
            } else {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 500,
                onClose: () => {
                  this.loading = false
                  const pageParams = {
                    pageNo: this.queryParams.pageNo,
                    pageSize: this.queryParams.pageSize,
                  }
                  this.$router.push({
                    path: '/custom/list',
                    query: pageParams,
                  })
                },
              })
            }
          }).catch(() => {
            this.loading = false
          })
        }
        this.loading = false
      })
    }, 1000, { leading: true, trailing: false }),
  },
}
</script>
